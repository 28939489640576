import React, { useEffect, useState } from 'react';
import { Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import { getCountriesService, getStatesService } from '../../services/transactionsService';

const BillingPaymentInformation = ({
    register,
    errors,
    setErrors,
    handleCopyPassengerDetails = () => {},
    copyPassengerDetails = null,
    tripSetting,
    states = [],
    countries = [],
}) => {
    const clearErrors = (key) => {
        if (errors.includes(key)) setErrors(errors.filter((error) => error !== key));
    };
    return (
        <>
            <Form.Label className=" text-uppercase payment-form-heading" style={{ color: `${tripSetting.textColor}` }}>
                Billing Information
            </Form.Label>
            <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                <Row>
                    {copyPassengerDetails && (
                        <Col xs={12} md={6} lg={6}>
                            <Form.Check
                                className={`my-1 text-align-center`}
                                type="checkbox"
                                value={copyPassengerDetails}
                                name="mainContact"
                                label="Copy Main Passenger Info"
                                onChange={handleCopyPassengerDetails}
                            />
                        </Col>
                    )}
                    <Col xs={12}>
                        <FormGroup className="d-flex payment-form-group">
                            <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>
                                NAME*
                            </Form.Label>
                            <div className="d-flex flex-column payment-form-inpu-group w-100">
                                <Form.Control
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    placeholder="First Name"
                                    {...register('firstName')}
                                    isInvalid={errors.includes('firstName')}
                                    className="payment-form-input"
                                    onChange={(e) => {
                                        clearErrors('firstName');
                                    }}
                                />
                                <FormControl.Feedback id="description" type="invalid">
                                    First Name is required
                                </FormControl.Feedback>

                                <Form.Control
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Last Name"
                                    {...register('lastName')}
                                    isInvalid={errors.includes('lastName')}
                                    className="payment-form-input"
                                    onChange={() => clearErrors('lastName')}
                                />
                                <FormControl.Feedback id="description" type="invalid">
                                    Last Name is required
                                </FormControl.Feedback>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className="d-flex payment-form-group">
                            <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>
                                EMAIL*
                            </Form.Label>
                            <div className="d-flex flex-column payment-form-inpu-group w-100">
                                <Form.Control
                                    type="text"
                                    id="email"
                                    name="email"
                                    placeholder="Email"
                                    {...register('email')}
                                    isInvalid={errors.includes('email')}
                                    className="payment-form-input"
                                    onChange={() => clearErrors('email')}
                                />
                                <FormControl.Feedback id="description" type="invalid">
                                    Email is required
                                </FormControl.Feedback>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className="d-flex payment-form-group">
                            <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>
                                BILLING ADDRESS*
                            </Form.Label>
                            <div className="d-flex flex-column payment-form-inpu-group w-100">
                                <Form.Control
                                    type="text"
                                    id="street"
                                    name="street"
                                    required={true}
                                    placeholder="Address Line 1"
                                    {...register('street')}
                                    isInvalid={errors.includes('street')}
                                    className="payment-form-input"
                                    onChange={() => clearErrors('street')}
                                />
                                <FormControl.Feedback id="description" type="invalid">
                                    Street Address is required
                                </FormControl.Feedback>

                                <Form.Control
                                    type="text"
                                    id="street2"
                                    name="street2"
                                    placeholder="Address Line 2"
                                    {...register('street2')}
                                    className="payment-form-input"
                                    onChange={() => clearErrors('street2')}
                                />
                                <Form.Control
                                    type="text"
                                    id="city"
                                    name="city"
                                    required={true}
                                    placeholder="City"
                                    {...register('city')}
                                    isInvalid={errors.includes('city')}
                                    className="payment-form-input"
                                    onChange={() => clearErrors('city')}
                                />
                                <FormControl.Feedback id="description" type="invalid">
                                    City is required
                                </FormControl.Feedback>

                                <div className="d-flex payment-form-select-group">
                                    <Form.Control
                                        as="select"
                                        id="state"
                                        name="state"
                                        placeholder="State"
                                        className="payment-form-select"
                                        {...register('state')}
                                        isInvalid={errors.includes('state')}
                                        onChange={() => clearErrors('state')}
                                    >
                                        {states.length &&
                                            states.map((r, i) => (
                                                <option key={i} value={r.abbreviation}>
                                                    {r.abbreviation}
                                                </option>
                                            ))}
                                    </Form.Control>
                                    <FormControl.Feedback id="description" type="invalid">
                                        State is required
                                    </FormControl.Feedback>

                                    <Form.Control
                                        type="text"
                                        id="zip"
                                        name="zip"
                                        required={true}
                                        placeholder="Zip"
                                        {...register('zip')}
                                        isInvalid={errors.includes('zip')}
                                        className="payment-form-select"
                                        onChange={() => clearErrors('zip')}
                                    />
                                    <FormControl.Feedback id="description" type="invalid">
                                        Zip Name is required
                                    </FormControl.Feedback>

                                    <Form.Control
                                        as="select"
                                        id="country"
                                        name="country"
                                        className="payment-form-select"
                                        placeholder="country"
                                        {...register('country')}
                                        isInvalid={errors.includes('country')}
                                        onChange={() => clearErrors('country')}
                                    >
                                        {countries &&
                                            countries.length > 0 &&
                                            countries.map((r, i) => (
                                                <option key={i} value={r.alpha2Code}>
                                                    {r.name}
                                                </option>
                                            ))}
                                    </Form.Control>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>

            <Form.Label className=" text-uppercase payment-form-heading" style={{ color: `${tripSetting.textColor}` }}>
                Payment Information
            </Form.Label>
            <div className="payment-form-desc-main" style={{ backgroundColor: `${tripSetting.mainColor}` }}>
                <Row>
                    <Col xs={3}>
                        <Form.Label className="payment-form-label" style={{ color: `${tripSetting.textColor}` }}>
                            CREDIT CARD*
                        </Form.Label>
                    </Col>
                    <Col xs={9}>
                        <Row>
                            <Col xs={12}>
                                <FormGroup className="d-flex payment-form-group mb-1">
                                    <div className="d-flex flex-column payment-form-inpu-group w-100">
                                        <Form.Control
                                            type="text"
                                            id="cardNumber"
                                            name="cardNumber"
                                            required={true}
                                            maxLength={16}
                                            placeholder="Card Number*"
                                            {...register('cardNumber')}
                                            isInvalid={errors.includes('cardNumber')}
                                            className="payment-form-input"
                                            onChange={() => setErrors([])}
                                        />
                                        <FormControl.Feedback id="description" type="invalid">
                                            Please Enter a Card Number
                                        </FormControl.Feedback>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="w-100">
                                        <Form.Control
                                            type="text"
                                            id="cardExpiration"
                                            name="cardExpiration"
                                            required={true}
                                            placeholder="Expiration Date* (MMYY)"
                                            maxLength={4}
                                            {...register('cardExpiration')}
                                            isInvalid={errors.includes('cardExpiration')}
                                            className="form-sub-input"
                                            onChange={() => setErrors([])}
                                        />
                                        <FormControl.Feedback id="description" type="invalid">
                                            Card Expiration Date is required
                                        </FormControl.Feedback>
                                    </div>

                                    <div className="pl-1">
                                        <Form.Control
                                            type="text"
                                            id="cvv"
                                            name="cvv"
                                            required={true}
                                            placeholder="CVV*"
                                            maxLength={4}
                                            {...register('cvv')}
                                            isInvalid={errors.includes('cvv')}
                                            className="form-cvv-input"
                                        />
                                        <FormControl.Feedback id="description" type="invalid">
                                            CVV is required
                                        </FormControl.Feedback>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default BillingPaymentInformation;
